import React, { useState, useEffect } from 'react';
import { useNavTitle } from '@/state';
import { createForm } from 'rc-form';
import { List, InputItem, WhiteSpace, Button, Checkbox, Result, NoticeBar } from 'antd-mobile';
import { useWxInfo } from '@/state';
import _ from 'lodash';

// Wx AppID
const AppID = "wx0fb9b43e6e5eb51b";

const CheckboxItem = Checkbox.CheckboxItem;

const StuffSelector = React.forwardRef((props, ref) => {
  const data = [
    { value: '石榴鸡肉', label: '石榴鸡肉' },
    { value: '鲱鱼甜橙', label: '鲱鱼甜橙' },
  ];

  const onChange = key => {
    if(_.includes(props.value, key)) {
      props.onChange(_.difference(props.value, [key]));
    } else {
      props.onChange((props.value || []).concat(key))
    }
  };

  return (<div>
    {data.map(i => (
      <CheckboxItem ref={ref} checked={_.includes(props.value, i.value)} key={i.value} onChange={() => onChange(i.value)}>
        {i.label}
      </CheckboxItem>
    ))}
  </div>);
});

const TryOutForm = props => {

  const { getFieldProps } = props.form;
  const [wx] = useWxInfo();
  const [notice, setNotice] = useState("");
  const clearNotice = () => setNotice("");

  const handleSubmit = () => {
    props.form.validateFields((error, value) => {
      if(_.isEmpty(error)) {
        alert("success!")
        return;
      }

      const key = _.keys(error)[0];
      setNotice(_.get(error, [key, "errors", 0, "message"]));
    });
  };

  return (
    <div>
      {notice && <NoticeBar mode="closable" onClick={clearNotice}>
        {notice}
      </NoticeBar>}
      <List renderHeader={() => '收货信息'}>
        <InputItem
          {...getFieldProps('name', {
            rules: [{
              required: true,
              message: '没有收货人姓名无法邮寄哦',
            }],
          })}
          placeholder={wx.openid}
        >收货人</InputItem>
        <InputItem
          {...getFieldProps('phone', {
            rules: [{
              validator: async (rule, value) => {
                if(!value) {
                  throw new Error('手机号用来接收物流信息哦')
                }
                if(value.length !== 13){ 
                  throw new Error('看起来手机号不太正确')
                } 
              }
            }],
          })}
          type="phone"
          placeholder="收货人手机号"
        >手机号</InputItem>
        <InputItem
          {...getFieldProps('address', {
            rules: [{
              required: true,
              message: '地址是通过审核的关键信息，请务必填写',
            }],
          })}
          placeholder="详细收货地址"
        >收货地址</InputItem>
      </List>
      <List renderHeader={() => '试吃装选择（至少选一个）'}>
        <StuffSelector {...getFieldProps('stuff', {
          rules: [{
            validator: async (rule, value) => {
              if(_.isEmpty(value)) {
                throw new Error("请至少选择一种试吃装")
              }
            }
          }]
        })}/>
      </List>
      <WhiteSpace/>
      <Button type='primary' onClick={handleSubmit}>提交</Button>
    </div>
  );
}

const TryOut = () => {
  const TryOutFormWrapper = createForm()(TryOutForm);
  useNavTitle( "法明娜（Farmina）试吃领取");

  const [wx] = useWxInfo();

  useEffect(() => {
    if(!wx.openid) {
      const redirect = encodeURIComponent(window.location.origin + "/wx_oauth")
      const state = encodeURIComponent(window.location.pathname + window.location.search);
      const href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${AppID}&redirect_uri=${redirect}&response_type=code&scope=snsapi_base&state=${state}#wechat_redirect`;
      window.location.href = href;
    }
  });

  if(!wx.openid) {
    return (
      <Result
        img={<img src="https://gw.alipayobjects.com/zos/rmsportal/HWuSTipkjJRfTWekgTUG.svg" className="spe am-icon am-icon-lg" alt="" />}
        title="登陆中..."
        message={`验证微信环境`}
      />
    );
  }

  return (
    <TryOutFormWrapper/>
  );
};

export default TryOut;