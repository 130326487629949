import React, { useState } from 'react';
import { Popover, NavBar, Icon } from 'antd-mobile';
import { useLocation, useHistory } from 'react-router-dom';
import { useNavTitle } from '@/state';
import { icon } from '@/icon';
import _ from 'lodash';

const Item = Popover.Item;

const homeIcon = pathname => {
    if(pathname !== "/") {
        return icon("house");
    }
    return null
};

const Navigation = props => {
    const history = useHistory();
    const location = useLocation();
    const [title] = useNavTitle();
    const [visible, setVisible] = useState(false);
    const back = () => {
        history.push("/");
    };
    const onSelect = opt => {
        setVisible(false);
        history.push(opt.key);
    };

    return (
        <NavBar
            mode="light"
            icon={homeIcon(_.get(location, "pathname"))}
            onLeftClick={back}
            rightContent={
            <Popover mask
                overlayClassName="fortest"
                overlayStyle={{ color: 'currentColor' }}
                visible={visible}
                overlay={[
                    (<Item key="/verify" icon={icon("monitor")}>防伪验真</Item>),
                    //(<Item key="/raws" icon={icon("pie-chart")}>生骨肉</Item>),
                    //(<Item key="/news" icon={icon("mail")}>小道消息</Item>),
                ]}
                align={{
                    overflow: { adjustY: 0, adjustX: 0 },
                    offset: [-10, 0],
                }}
                onVisibleChange={setVisible}
                onSelect={onSelect}
            >
                <Icon type="ellipsis" />
            </Popover>
            }
        >
            {title || '尾巴联萌'}
        </NavBar>
    );
}

export default Navigation;