import React from 'react';
import { List } from 'antd-mobile';
import { useNavTitle } from '@/state';
import { useHistory } from 'react-router-dom';

const Item = List.Item;
const Brief = Item.Brief;

const Home = () => {
  useNavTitle("尾巴联萌");
  const history = useHistory();
  const to = url => () => history.push(url);

  return (
    <List renderHeader={() => '产品列表'}>
        <Item arrow="horizontal" multipleLine onClick={to("/farmina")}>
          法明娜（Farmina） <Brief>意大利进口高端宠粮</Brief>
        </Item>
    </List>
  );
}

export default Home;