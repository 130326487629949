import React, { useEffect } from 'react';
import _ from 'lodash';

const defaultGlobalState = {
    title: '尾巴联萌',
    wx: {
      openid: "",
      signature: "",
    },
};

const globalStateContext = React.createContext(defaultGlobalState);
const dispatchStateContext = React.createContext(undefined);

const useGlobalState = () => [
    React.useContext(globalStateContext),
    React.useContext(dispatchStateContext)
];

const useNavTitle = setTo => {
  const [state, dispatch] = useGlobalState();
  const title = _.get(state, "title");
  const setter = title => {
    dispatch({
      ...state,
      title,
    });
  };

  useEffect(() => {
    if(setTo && title !== setTo) {
      setter(setTo);
    }
  });

  return [_.get(state, "title"), setter];
};

const useWxInfo = () => {
  const [state, dispatch] = useGlobalState();
  const wx = _.get(state, "wx");
  const setter = wx => {
    dispatch({
      ...state,
      wx,
    });
  };

  return [wx, setter];
};

const GlobalStateProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(
      (state, newValue) => ({ ...state, ...newValue }),
      defaultGlobalState
    );
    return (
      <globalStateContext.Provider value={state}>
        <dispatchStateContext.Provider value={dispatch}>
          {children}
        </dispatchStateContext.Provider>
      </globalStateContext.Provider>
    );
};

export {
    GlobalStateProvider,
    useGlobalState,
    useNavTitle,
    useWxInfo,
};