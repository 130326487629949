import React from 'react';
import './App.less';
import Navigation from './Navigation';
import { Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import Farmina from './pages/Farmina';
import Wip from './pages/Wip';
import TryOut from './pages/TryOut';
import WxOauth from './pages/WxOauth';
import Verify from './pages/Verify';

const App = () => {
  return (
    <div>
      <Navigation/>
      <Switch>
        <Route exact path="/">
            <Home/>
        </Route>
        <Route path="/news">
          <Wip/>
        </Route>
        <Route path="/raws">
          <Wip/>
        </Route>
        <Route path="/farmina">
            <Farmina/>
        </Route>
        <Route path="/try">
            <TryOut/>
        </Route>
        <Route path="/wx_oauth">
            <WxOauth/>
        </Route>
        <Route exact path="/verify">
            <Verify/>
        </Route>
        <Route path="/verify/:code">
            <Verify/>
        </Route>
      </Switch>
    </div>
  );
}

export default App;
