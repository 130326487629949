import React, { useEffect, useState } from 'react';
import { Result, Icon } from 'antd-mobile';
import { useHistory, useLocation } from 'react-router-dom';
import { useWxInfo } from '@/state';
import axios from 'axios';
import qs from 'qs';

const PROCESSING = "processing";
const SUCCESS = "success";
const FAILED = "failed";

const WxOauth = () => {
    const history = useHistory();
    const location = useLocation();
    const [wx, setWx] = useWxInfo();
    const [status, setStatus] = useState(PROCESSING);
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })

    useEffect(() => {
        if(wx.openid && wx.signature) {
            setWx(wx);
            history.push(query.state);
        } else {
            axios.get("https://api.fakeio.com/wx_oauth/" + query.code).then(resp => {
                const wx = resp.data;

                if(wx && wx.openid && wx.signature) {
                    setWx(wx);
                    history.push(query.state);
                } else {
                    setStatus(FAILED);
                }
            }, () => {
                setStatus(FAILED);
            });

        }
    }, [query.code, query.state, wx, setWx, history]);

    switch(status) {
        case SUCCESS:
            return (
                <Result
                    img={<Icon type="check-circle" className="spe" style={{ fill: '#1F90E6' }} />}
                    title="登陆成功"
                    message={"页面跳转中..."}
                />
            );
        case FAILED:
            return (
                <Result
                    img={<Icon type="cross-circle-o" className="spe" style={{ fill: '#F13642' }} />}
                    title="登陆失败"
                    message={"服务器开小差啦"}
                />
            );
        default:
            return (
                <Result
                    img={<img src="https://gw.alipayobjects.com/zos/rmsportal/HWuSTipkjJRfTWekgTUG.svg" className="spe am-icon am-icon-lg" alt="" />}
                    title="登陆中..."
                    message={`验证身份...`}
                />
            );
    }
};

export default WxOauth;