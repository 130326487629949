
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import axios from 'axios';
import { Result, InputItem, List, Button, WhiteSpace, WingBlank } from 'antd-mobile';
import _ from 'lodash';
import { useNavTitle } from '@/state';
import moment from 'moment';
import localization from 'moment/locale/zh-cn';
import './index.less';

moment.updateLocale('zh-cn', localization);

const handleInputChange = setInput => input => {
    if(!input) {
        setInput('');
        return
    }
    setInput(input.toUpperCase().replaceAll(' ', '').substring(0,16).match(/.{1,4}/g).join(' '));
};

const ResultPanel = props => {
    const { code } = props; 
    const [result, setResult] = useState(null);
  
    useEffect(() => {
      if(result) {
        return;
      }
  
      axios.post("https://api.fakeio.com/verification/" + code).then(resp => {
        if(_.get(resp, 'data.count') > 1) {
          setResult(
            <Result title="该产品被查询多次"
                message={<div>
                    <div>产品名称：{_.get(resp, 'data.product')}</div>
                    <div>{_.get(resp, 'data.channel')}：{_.get(resp, 'data.provider')}</div>
                    <div>当前为第 {_.get(resp, 'data.count')} 次查询</div>
                    <div>第一次查询时间：{moment(_.get(resp, 'data.first') * 1000).format('lll')}</div>
                </div>}
            />
          );
        } else {
          setResult(
            <Result title="该商品为官方正品"
                message={<div>
                    <div>产品名称：{_.get(resp, 'data.product')}</div>
                    <div>{_.get(resp, 'data.channel')}：{_.get(resp, 'data.provider')}</div>
                </div>}/>
          );
        }
      }).catch(err => {
        if(_.get(err, "response.status") === 404) {
          setResult(
            <Result title="查无此码"
                message={<div>
                    <div>您购买的商品非官方正品</div>
                    <div>请注意维护自己的权益</div>
                </div>}
            />
          );
        } else {
          setResult(
            <Result title="系统错误"
                message="请稍后再试"
            />
          );
        }
      });
    });
  
    return (<div>
        {result && result}
        {!result && <Result
            img={<img src="https://gw.alipayobjects.com/zos/rmsportal/HWuSTipkjJRfTWekgTUG.svg" className="spe am-icon am-icon-lg" alt="" />}
            title="验证中..."
            message={`请稍等...`}
        />}
    </div>);
  };

const Verify = () => {
    const { code } = useParams();
    const [input, setInput] = useState("");
    const history = useHistory();

    useNavTitle("产品真伪查询");

    if(code) {
        return (<ResultPanel code={code}/>);
    }

    return (<div className="verify">
        <List renderHeader={() => ''}>
            <InputItem placeholder="请输入16位防伪码" onChange={handleInputChange(setInput)} value={input}/>
            <WhiteSpace size='lg'/>
            <WingBlank size='lg'>
                <Button 
                    type='primary' 
                    disabled={input.replaceAll(' ', '').length < 16}
                    onClick={() => history.push(`/verify/${input.replaceAll(' ', '')}`)}
                >查询</Button>
            </WingBlank>
            <WhiteSpace size='lg'/>
        </List>
    </div>);
};

export default Verify;