import React from 'react';
import './index.less';
import { NoticeBar } from 'antd-mobile';
import { useNavTitle } from '@/state';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

const pics = [
    "d.jpeg",
];

const details = () => (<div>
    {_.map(pics, pic => <img key={pic} src={`/img/farmina/${pic}`} alt="" className="detail"/>)}
</div>);

const Farmina = () => {
  useNavTitle("法明娜（Farmina）");
  const history = useHistory();

  return (
    <div className="farmina">
        <NoticeBar mode="link" onClick={() => history.push("/try")}>
            法明娜（Farmina）试吃装领取通道
        </NoticeBar>
        {details()}
    </div>
  );
}

export default Farmina;