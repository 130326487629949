import React from 'react';
import { Result } from 'antd-mobile';

const Wip = () => {
    return (
        <Result
            img={<img src="https://gw.alipayobjects.com/zos/rmsportal/GIyMDJnuqmcqPLpHCSkj.svg" className="spe am-icon am-icon-lg" alt="" />}
            title="页面施工中"
            message="暂时无法提供服务，请见谅"
        />
    );
};

export default Wip;